import React from 'react'

import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'
import Content from 'components/Content';

function addGradient(imageProp, toTop = false) {
  let gradient;

  if (toTop) {
    gradient = [
      imageProp,
      `linear-gradient(to top, rgba(0, 83, 112, 0) 0%, #008cbc 150%)`
    ].reverse();
  } else {
    gradient = [
      imageProp,
      `linear-gradient(to bottom, rgba(0, 83, 112, 0) 22%, #008cbc 104%)`
    ].reverse();
  }

  return gradient;
}

const Case = ({ klantcase }) => {
  return (
    <section className="case">
      <div className="container-fluid">
        <BackgroundImage
          Tag="div"
          fluid={addGradient(klantcase.background.localFile.childImageSharp.fluid, true)}
          className="case-image"
        />
        <div className="row justify-content-end">
          <div className="col-sm-9 col-md-6 pr-0">
            <div className="case-card d-flex">
              <div className="pl-5">
                <p>{klantcase.relations.acf.quote.quoted_person.name}</p>
                <p>{klantcase.relations.acf.quote.quoted_person.function}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <Content className="case-quote" content={klantcase.relations.acf.quote.content} />
          </div>
        </div>
        <div className="row justify-content-center">
          <Link className="button case-cta" to={klantcase.relations.path}>
            {klantcase.cta}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Case;
