import React from 'react'
import { getLanguage } from '../../services/language'

import { Link } from 'gatsby';
import Image from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import Content from 'components/Content';

function addGradient(imageProp, toTop = false) {
  let gradient;

  if (toTop) {
    gradient = [
      imageProp,
      `linear-gradient(to top, rgba(0, 83, 112, 0) 0%, #008cbc 150%)`
    ].reverse();
  } else {
    gradient = [
      imageProp,
      `linear-gradient(to bottom, rgba(0, 83, 112, 0) 22%, #008cbc 104%)`
    ].reverse();
  }

  return gradient;
}

const Deliver = ({ deliver }) => {
  const language = getLanguage()

  return (
    <section className="deliver">
      <Image
        loading="eager"
        className="deliver-image"
        fluid={deliver.background.localFile.childImageSharp.fluid}
      />
      <div className="container">
        <h2 className="with-dot mb-4">{deliver.title}</h2>
        <div className="row">
          {deliver.functions.map((_function, key) => (
            <div key={key} className="col-md-6 col-lg-3 mb-5">
              <BackgroundImage
                className="deliver-card"
                Tag="div"
                fluid={addGradient(_function.image.localFile.childImageSharp.fluid)}
              >
                <span className="deliver-card--title with-dot">{_function.name}</span>
              </BackgroundImage>
            </div>
          ))}
          <div className="col-md-12 text-center">
            <Content className="mb-5" content={deliver.before_cta} />
            <Link className="button" to={deliver.cta.relations.path}>
              {deliver.cta.label}
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Deliver;
