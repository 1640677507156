import React from 'react'
import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO';
import Content from 'components/Content';

import Deliver from 'components/home/Deliver';
import Case from 'components/home/Case';
import About from 'components/home/About';
import Blog from 'components/home/Blog';

import "styles/HomeTemplate.scss";

const Home = ({ data, location }) => {
  const { wordpressPage: page } = data
  const { acf: { hero, deliver, klantcase, about, projecten } } = page;
  const url = location.href ? location.href : '';

  return (
    <Layout>
      <SEO yoast={{ meta: page.yoast_meta }} lang={page.wpml_current_locale.slice(0, 2)} location={url} />
      <div className="home-page">
        <section className="hero">
          <Image
            loading="eager"
            fadeIn={false}
            fluid={hero.image.localFile.childImageSharp.fluid}
            className="hero-image"
          />
          <div className="container hero-text">
            <div className="col-lg-8 col-xl-6">
              <h1><Content className="with-dot-nested" content={hero.text} /></h1>
            </div>
          </div>
          <div className="container hero-column d-flex">
            <div className="row justify-content-end align-items-end">
              <div className="col-md-10 col-lg-8 col-xl-6 card d-block">
                <h2 className="with-dot mb-4">{hero.block.title}</h2>
                <Content className="mb-4" content={hero.block.content} />
                <Link className="button" to={hero.block.cta.relations.path}>
                  {hero.block.cta.label}
                </Link>
              </div>
            </div>
          </div>
        </section>

        <Deliver deliver={deliver} />
        <Case klantcase={klantcase} />
        <About about={about} />
        {/*<Blog projecten={projecten} />*/}
      </div>
    </Layout>
  )
}

export default Home;

export const query = graphql`
  query($wordpress_id: Int!) {
    wordpressPage(
      wordpress_id: { eq: $wordpress_id }
    ) {
      id
      wordpress_id
      title
      slug
      content
      wpml_current_locale
      yoast_meta {
        name
        content
        property
      }
      acf {
        # Hero section
        hero {
          text
          block {
            content
            title
            cta {
              label
              relations {
                path
              }
            }
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        # Deliver section
        deliver {
          title
          functions {
            name
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          before_cta
          cta {
            label
            relations {
              path
            }
          }
          background {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1529) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        # Klantcase section
        klantcase: case {
          background {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1440) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          cta
          relations {
            slug
            path
            acf {
              quote {
                content
                quoted_person {
                  name
                  function
                }
              }
            }
          }
        }

        # About section
        about {
          title
          columns {
            title
            content
          }
          cta {
            label
            relations {
              path
            }
          }
        }


        # Blog - projecten section
        projecten {
          title
          cta
          cta_overview {
            label
            relations {
              path
            }
          }
          relations {
            title
            path
            excerpt
            slug
            acf {
              subtitle
              banner {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 1440) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
