import React from 'react'

import { Link } from 'gatsby';
import Content from 'components/Content';

const About = ({ about }) => {
  return (
    <section className="about">
      <div className="container">
        <h2 className="with-dot">{about.title}</h2>

        <div className="row mb-md-0 mb-lg-5">
          {about.columns.map((column, key) => (
            <div key={key} className="col-md-12 col-lg-4 mb-5 mb-lg-0">
              <div className="about-card">
                <h3 className="with-dot text-center">{column.title}</h3>
                <Content content={column.content} />
              </div>
            </div>
          ))}
        </div>

        <div className="row justify-content-center">
          <Link className="button" to={about.cta.relations.path}>
            {about.cta.label}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default About;
